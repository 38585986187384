<template>
  <div>
    <el-row :gutter="20" class="search-box">
      <el-col :span="4">
        <el-select v-model="query.isPass" @change="getList(1)" clearable>
        
          <el-option
            v-for="(item, index) in this.const.AUDIT_STATUS"
            :label="item.name"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-col>

     
    </el-row>

    <el-table
      border
      :data="dataSource"
      class="page-top-space"
      row-class-name="table-row"
    >
      <el-table-column type="selection"></el-table-column>

      <el-table-column label="名称" prop="userName"></el-table-column>

      <el-table-column label="地址" prop="city">
        <template #default="scope">
          <span
            >{{ scope.row.province }}/{{ scope.row.city }}/{{
              scope.row.area
            }}/{{ scope.row.address }}</span
          >
        </template>
      </el-table-column>

      <el-table-column label="状态" prop="type">
        <template #default="scope">
          <eb-badge
            :list="this.const.AUDIT_STATUS"
            :target="scope.row.isPass"
          ></eb-badge>
        </template>
      </el-table-column>

       <el-table-column label="备注" prop="info"></el-table-column>

      <el-table-column label="操作" prop="opeartion">
        <template #default="scope">
          <span v-if="scope.row.isPass !== this.const.AUDIT_STATUS_CODE.PASS"
            class="option option-primary"
            @click="doAudit(scope.row, this.const.AUDIT_STATUS_CODE.PASS)"
            >通过申请</span
          >
          <span
          v-if="scope.row.isPass !== this.const.AUDIT_STATUS_CODE.REJECT"
            class="option option-danger"
            @click="doAudit(scope.row, this.const.AUDIT_STATUS_CODE.REJECT)"
            >驳回申请</span
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      layout=" total,prev, pager, next"
      background
      :total="total"
      :page-size="pages.pageSize"
      :current-page="pages.pageIndex"
      @current-change="getList"
    ></el-pagination>
  </div>
</template>

<script>
import { fetchPresenterList, presenterAudit } from "@/api/user";

export default {
  data() {
  
    return {
      
      dataSource: [],
      modalData: {},
      pages: {
        pageIndex: 1,
        pageSize: 10,
      },
      query: {
        area: "",
        city: "",
        userName: "",
        userId: -1,
        province: "",
        phone: "",
        isPass: -1,
        ruleId: -1,
        email: "",
      },
      total: 0,
    };
  },

  mounted() {
    this.getList();
  },

  methods: {
    getList(current) {
      if (!this.validatenull(current)) {
        this.pages.pageIndex = current;
      }
      fetchPresenterList({ ...this.query, ...this.pages }).then(
        (res) => {
          let list = res.data.data.records || [];
          this.total = res.data.data.total;
          this.dataSource = list;
        }
      );
    },

    doAudit(data, type) {
      presenterAudit({
        id: data.id,
        isPass: type,
        ruleId: 0,
      }).then((res) => {
        this.$message.success("操作成功");
        this.getList();
        
      });
    },
  },
};
</script>
